import React from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { init, replayIntegration, reactRouterV6BrowserTracingIntegration } from '@sentry/react'

import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import store from 'core/store'
import { GIT_VERSION, SENTRY_DSN } from 'config/app'

import App from './App'

/* eslint-disable no-console */
console.log(`%c Probatix Frontend App - ${GIT_VERSION}`, 'font-size: 0.875rem; font-weight: bold')
/* eslint-enable no-console */

if (!process.env.REACT_APP_APP_ENV?.startsWith("local")) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_DEMO_ENV ? 'GTM-W7VL39X' : 'GTM-T2F3VPHG',
  }

  TagManager.initialize(tagManagerArgs)
}

if ('' !== process.env.SENTRY_DSN) {
  init({
    dsn: SENTRY_DSN,
    environment: 'prod',
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
      }),
      replayIntegration(),
    ],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
)
